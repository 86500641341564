import { CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { Doughnut, Line, Pie } from 'react-chartjs-2'

const chartComponentByType = {
  pie: Pie,
  line: Line,
  doughnut: Doughnut,
}

const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit,
  },
  chart: {
    width: '100%',
  },
})

function ModelStats(props) {
  const {
    api,
    modelName,
    classes,
    className,
    counts,
  } = props

  const [state, setState] = React.useState({
    isLoading: !!counts,
    title: props.title,
    subtitle: props.subtitle,
    graph: props.graph,
  })

  React.useEffect(() => {
    if (!counts || !api) return
    const { request, cancel } = api.getStats(modelName, counts)
    const fetchStats = async () => {
      const data = await request
      // console.log(data)
      setState({ ...data, isLoading: false })
    }
    fetchStats()
    return () => {
      cancel()
    }
  }, [api, counts, modelName])

  console.log({ data: state.graph.data, type: state.type })
  const Component = chartComponentByType[state.graph.type]

  return (
    <Paper elevation={1} className={className}>
      {state.isLoading ? (
        <div style={{ textAlign: 'center', padding: 20 }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container className={classes.root}>
          <Grid xs={4} item>
            <Typography variant={'h5'}>{state.title}</Typography>
            <Typography>
              <b>{state.subtitle}</b>
            </Typography>
          </Grid>
          <Grid xs={8} item>
            {Component && (
              <div className={classes.chart}>
                <Component
                  options={{
                    maintainAspectRatio: false,
                    circumference: Math.PI,
                    rotation: -Math.PI,
                  }}
                  legend={{
                    position: 'right',
                  }}
                  width={300}
                  height={100}
                  data={state.graph.data}
                />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </Paper>
  )
}

export default withStyles(styles)(ModelStats)
