import React, { useEffect, useRef } from 'react'
import { Bar, Bubble, Doughnut, HorizontalBar, Line, Pie, Polar, Radar, Scatter } from 'react-chartjs-2'

import { withLanguage } from '../../../LanguageContext'

const componentByType = {
  doughnut: Doughnut,
  pie: Pie,
  line: Line,
  scatter: Scatter,
  bar: Bar,
  horizontalBar: HorizontalBar,
  radar: Radar,
  polar: Polar,
  bubble: Bubble,
}

const Chart = (props) => {
  const { data, chartType, options = {}, selectedLanguage } = props
  const Component = componentByType[chartType] || Pie
  const chartRef = useRef(null)

  ;['xAxes', 'yAxes'].forEach((axis) => {
    const defaultTicks = {
      beginAtZero: true,
      min: 0,
      // Set scale for charts without data
      max: data?.datasets?.length ? undefined : 10,
      // Format ticks value with thousands separator
      callback: (value) => value.toLocaleString(selectedLanguage.locale),
    }
    if (!options.scales) options.scales = { [axis]: [{ ticks: defaultTicks }] }
    if (!options.scales[axis]) options.scales[axis] = [{ ticks: defaultTicks }]
    if (!options.scales[axis][0]?.ticks) options.scales[axis][0].ticks = defaultTicks
    options.scales[axis][0].ticks = {
      ...options.scales[axis][0].ticks,
      ...defaultTicks,
    }
  })

  // Format tooltip labels with thousands separator
  options.tooltips = {
    callbacks: {
      label: function (tooltipItem, data) {
        const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || ''
        const value = tooltipItem.xLabel.toLocaleString(selectedLanguage.locale)
        let percentage = ''
        if (data.datasets.length === 2) {
          const otherDatasetIndex = tooltipItem.datasetIndex === 0 ? 1 : 0
          const dataIndex = data.datasets[tooltipItem.datasetIndex].data.indexOf(tooltipItem.xLabel)
          const total = data.datasets[otherDatasetIndex].data[dataIndex] + tooltipItem.xLabel
          const currentValue = tooltipItem.xLabel
          const percentageValue = Math.round((currentValue / total) * 100)
          percentage = `(${isNaN(percentageValue) ? 0 : percentageValue}%)`
        }
        return `${datasetLabel}: ${value} ${percentage}`
      },
    },
  }

  // Add the striped pattern when the chart is mounted
  useEffect(() => {
    if (!chartRef.current) return
    const chartInstance = chartRef.current.chartInstance // Access the Chart.js instance
    const ctx = chartInstance.ctx // Access the 2D context

    const { backgroundColor = [] } = chartInstance.data.datasets[1] || {}
    if (!Array.isArray(backgroundColor) || backgroundColor.length === 0) return

    // Apply striped pattern to the second section of the stacked bars
    chartInstance.data.datasets[1].backgroundColor = backgroundColor.map((color, index) => {
      const stripeColor = chartInstance.data.datasets[0].backgroundColor[index]
      return createStripedPattern(ctx, color, stripeColor)
    })

    // Update the chart to apply the new style
    chartInstance.update()
  }, [])

  return (
    <React.Fragment>
      <div style={{ width: '100%' }}>
        <Component
          ref={chartRef}
          data={data}
          height={300}
          options={{ maintainAspectRatio: false, ...options }}
          redraw
        />
      </div>
    </React.Fragment>
  )
}

export default withLanguage(Chart)

// Function to create a striped pattern
const createStripedPattern = (context, bgColor, stripeColor) => {
  const canvas = document.createElement('canvas')
  canvas.width = 50
  canvas.height = 1000
  const patternCtx = canvas.getContext('2d')
  
  const numberOfStripes = 50
  const thickness = 5
  const moveFactor = 300

  for (let i = 0; i < numberOfStripes * 2; i++) {
    patternCtx.beginPath()
    patternCtx.strokeStyle = i % 2 ? bgColor : stripeColor
    patternCtx.lineWidth = thickness

    patternCtx.moveTo(i * thickness + thickness / 2 - moveFactor, 0)
    patternCtx.lineTo(0 + i * thickness + thickness / 2, moveFactor)
    patternCtx.stroke()
  }

  return context.createPattern(canvas, 'repeat')
}
